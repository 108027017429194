export default [
  {
    key: 'image',
    initKey: 'imageUrl',
    label: 'field.image',
    rules: '',
    type: 'single-image',
    pw: 200,
    ph: 200,
    fullWidth: false,
  },
  {
    cols: 12,
    hide: true,
  },
  {
    key: 'rewardName',
    label: 'field.name',
    rules: 'required',
    type: 'text',
  },
  {
    key: 'code',
    label: 'field.code',
    rules: 'required',
    type: 'text',
  },
  {
    key: 'point',
    label: 'field.point',
    rules: 'required|integer',
    type: 'currency',
  },
  {
    key: 'type',
    label: 'field.type',
    rules: 'required',
    type: 'nSingleSelection',
    options: [{
      id: 'item',
      label: 'field.item',
    }, {
      id: 'cash',
      label: 'field.cash',
    }],
    selectionKey: 'id',
    selectionLabel: 'label',
    translate: true,
    reduce: true,
  },
  {
    key: 'amount',
    label: 'field.amount',
    rules: 'required',
    type: 'currency',
  },
  {
    cols: 12,
    hide: true,
  },
  {
    key: 'isEnable',
    label: 'status.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
]
